<template>
  <div class="d-flex flex-direction-column justify-content-begin align-items-center">
    <h2>Domains</h2>
    <button v-if="isGlobalAdmin || isDomainAdmin" type="button" @click="addDomain" class="btn btn-sm btn-success ml-2"><BIconPlusSquare /> Add</button>
  </div>

  <table class="table bg-light">
    <thead>
      <tr>
        <th></th> <!-- Placeholder for caret icon -->
        <th>Name</th>
        <th>Description</th>
        <th v-if="isGlobalAdmin || isDomainAdmin"></th> <!-- placeholder for edit/remove domain button -->
        <th></th> <!-- Placeholder for whitespace -->
      </tr>
    </thead>
    <tbody>
      <template v-for="domain in domainList" :key="domain.name">
        <!--   The basic domain information that is always visible     -->
        <tr :class="{ 'white-background': domain.domainId === expandedDomain.domainId }">
          <td @click="toggleExpanded(domain)" class="action">
            <BIconCaretDownFill class="expander" v-if="domain.domainId === expandedDomain.domainId" />
            <BIconCaretRight class="expander" v-else />
          </td>
          <td>
            <span class="text-xl">{{ domain.domainName }}</span>
          </td>
          <td>
            <span>{{ domain.domainDescription }}</span>
          </td>
          <td :class="(isGlobalAdmin || isDomainAdmin) ? 'two-buttons' : 'one-button'" v-if="isGlobalAdmin || isDomainAdmin">
            <button @click="editDomain(domain)" type="button" class="btn btn-sm btn-primary"><BIconPen /> Edit</button>
            <button v-if="isGlobalAdmin || isDomainAdmin" @click="removeDomain(domain)" type="button" class="btn btn-sm btn-danger ml-2"><BIconTrashFill /> Remove</button>
          </td>
          <td></td> <!-- Placeholder for whitespace -->
        </tr>
        <!--   additional domain information that is expandable     -->
        <tr v-show="domain.domainId === expandedDomain.domainId" class="white-background">
          <td class="hide-top-border"></td>
          <td class="text-nowrap">
            <DomainAdminList :domainId="domain.domainId" :readOnly="!isGlobalAdmin" />
          </td>
          <td>
            <DomainPermissionsList
              v-if="domain.domainId === expandedDomain.domainId"
              :domainId="domain.domainId"
              :readOnly="!isDomainAdmin" />
          </td>
          <td v-if="isGlobalAdmin || isDomainAdmin"></td>
          <td class="hide-top-border"></td>
        </tr>
      </template>
    </tbody>
  </table>
  <a v-if="isDomainAdmin" @click=morePermissionsAlert href="#">Need access to a new/different domain?</a>

  <!-- New Domain Modal -->
  <AModal
    v-model="addDomainModalOpen"
    @click-outside="closeModal"
    :style="{ 'max-width': '30rem' }"
  >
    <template #header>
      <div class="d-flex flex-direction-column justify-content-begin align-items-center">
        <h3>Add Domain</h3>
      </div>
    </template>
    <template #body>
      <div class="form-group">
        <label for="newDomainName">Domain Name</label>
        <input
          v-model="newDomainName"
          name="newDomainName"
          id="newDomainName"
          type="text"
          placeholder="Domain Name (institution-name.business-context)"
          size="30"
          class="form-control"/>

        <p v-if="domainNameFormattingErrorFound" class="text-danger">
          Formatting Error: Check that the format of your domain name is valid.
          {{ domainNameFormatErrorStr }}
        </p>
        <small id="newPermissionHelpText" class="form-text text-muted">
          Domains should be named using the institution name followed by the business context, like: <code>ces.admissions</code>
        </small>
        <label for="newDomainDescription">Description</label>
        <input
          v-model="newDomainDescription"
          name="newDomainDescription"
          id="newDomainDescription"
          type="text"
          placeholder="Domain Description"
          size="100"
          class="form-control"/>
      </div>

    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeModal" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptAddModal" class="btn btn-success mr-2" :disabled="!newDomainName">Add <BIconPlusSquare /></button>
      </div>
    </template>
  </AModal>
  <!-- Edit Domain Modal -->
  <AModal
    v-model="updateDomainModalOpen"
    @click-outside="closeModal"
    :style="{ 'max-width': '30rem', 'max-height': '70rem' }">
    <template #header>
      <h3>Edit Domain</h3>
    </template>
    <template #body>
      <div class="form-group">
        <label for="editDomainName">Domain Name</label>
        <input
          v-model="newDomainName"
          name="editDomainName"
          id="editDomainName"
          type="text"
          placeholder="Enter Domain Name Here"
          size="30"
          class="form-control"
          disabled
        />

        <p v-if="domainNameFormattingErrorFound" class="text-danger">
          Formatting Error: Check that the format of your domain name is valid.
          {{ domainNameFormatErrorStr }}
        </p>
        <small id="newPermissionHelpText" class="form-text text-muted">
          Domains should be named using the institution name followed by the business context, like: <code>ces.admissions</code>
        </small>
        <label for="editDomainDescription">Description</label>
        <input
          v-model="newDomainDescription"
          name="editDomainDescription"
          id="editDomainDescription"
          type="text"
          placeholder="Enter Domain Description Here"
          size="100"
          class="form-control"/>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeModal" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptEditModal" class="btn btn-success mr-2">Update <BIconCheckCircleFill /></button>
      </div>
    </template>
  </AModal>
  <!-- Delete Domain Modal -->
  <AModal
    v-model="deleteDomainModalOpen"
    @click-outside="closeModal"
    :style="{ 'max-width': '30rem', 'max-height': '70rem' }">
    <template #header>
      <h3>Delete Domain</h3>
    </template>
    <template #body>
      <div class="removeDomainBody">
        <p class="text-xl text-danger">
        Are you sure you want to remove the domain <strong>{{ domainToEdit.domainName }}</strong>?
        <br>
        <br>
        All associated permissions, mappings, and domain admins will also be removed!
        </p>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeModal" class="btn btn-outline-secondary mr-2"><BIconXCircleFill /> Cancel</button>
        <button @click="acceptDeleteModal" class="btn btn-danger mr-2"><BIconTrashFill /> Remove</button>
      </div>
    </template>
  </AModal>
  <AToast />
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

import { useDomainStore } from '../stores/domainStore.js'
import { useUserInfoStore } from '../stores/userInfoStore.js'
import { useAllRolesStore } from '../stores/allRolesStore.js'
import { useGlobalAdminStore } from '../stores/globalAdminStore'
import { domainNameFormatErrorStr, domainNameRegex } from '../lib/constants'
import { createGlobalAdminHelpPopup } from '../lib/util'

import AModal from '../components/AModal.vue'
import AToast from '../components/AToast.vue'
import DomainAdminList from '../components/DomainAdminList.vue'
import DomainPermissionsList from '../components/DomainPermissionsList.vue'

// Grab the list of domains from the domains store
const domainStore = useDomainStore()
const { domainList } = storeToRefs(domainStore)

const allRolesStore = useAllRolesStore()
const globalAdminStore = useGlobalAdminStore()

// The logged-in user's role is userInfoStore.role
const userInfoStore = useUserInfoStore()
const { isGlobalAdmin, isDomainAdmin } = storeToRefs(userInfoStore)

// Interact with router
const route = useRoute()

// Used to expand one of the domains for more details
const expandedDomain = ref({})

// The domain we are editing / deleting
const domainToEdit = ref({})

// Determines if the update dialog should show
const updateDomainModalOpen = ref(false)

// Determines if the delete dialog should show
const deleteDomainModalOpen = ref(false)

// Determines if the add domain dialog should show
const addDomainModalOpen = ref(false)

// Input string for the text field inside the dialogs
const newDomainName = ref('')
const newDomainDescription = ref('')

const domainNameFormattingErrorFound = ref(false)

function morePermissionsAlert () {
  const globalAdminNames = globalAdminStore.globalAdminList.map(user => user.name)
  createGlobalAdminHelpPopup(globalAdminNames)
}

// Expander state management
function toggleExpanded (domain) {
  if (domain.domainId === expandedDomain.value.domainId) {
    // The expanded domain was clicked on again, close all
    expandedDomain.value = {}
  } else {
    expandedDomain.value = domain
  }
}

// Fetch data on page load
watch(() => route.path, async () => {
  // Make sure the list of users and domains has been retrieved
  await domainStore.getDomains()
  await allRolesStore.getAllUserRoles()
}, { immediate: true })

/***
 * Opens the add domain modal
 */
function addDomain () {
  addDomainModalOpen.value = true
}

/***
 * Opens the edit domain modal and sets selected domain to the domain param passed
 * @param domain the domain object to be edited
 */
function editDomain (domain) {
  domainToEdit.value = domain
  newDomainName.value = domain.domainName
  newDomainDescription.value = domain.domainDescription
  updateDomainModalOpen.value = true
}

/***
 * Removes the domain in params from the list in the store
 * @param domain
 */
async function removeDomain (domain) {
  domainToEdit.value = domain
  deleteDomainModalOpen.value = true
}

/***
 * Closes the modal windows
 */
function closeModal () {
  domainToEdit.value = {}
  addDomainModalOpen.value = false
  updateDomainModalOpen.value = false
  deleteDomainModalOpen.value = false
  domainNameFormattingErrorFound.value = false
  newDomainName.value = ''
  newDomainDescription.value = ''
}

async function acceptAddModal () {
  if (!domainNameRegex.test(newDomainName.value)) {
    domainNameFormattingErrorFound.value = true
    return
  }

  const newDomain = {
    domainName: newDomainName.value,
    domainDescription: newDomainDescription.value
  }

  await domainStore.addDomain(newDomain)
  await allRolesStore.getAllUserRoles()

  closeModal()
}

async function acceptEditModal () {
  if (!domainNameRegex.test(newDomainName.value)) {
    domainNameFormattingErrorFound.value = true
    return
  }

  const updatedDomain = domainToEdit.value
  updatedDomain.domainName = newDomainName.value
  updatedDomain.domainDescription = newDomainDescription.value

  await domainStore.updateDomain(updatedDomain)
  closeModal()
}

async function acceptDeleteModal () {
  console.log(domainToEdit)
  await domainStore.removeDomain(domainToEdit.value.domainId)
  closeModal()
}

</script>

<style scoped>
.two-buttons {
  width: 14rem;
  min-width: fit-content;
}
.text-xl {
  font-size: 1.3rem;
  font-weight: 600;
}
.expander {
  transition: transform .3s ease;
}
.white-background {
  background-color: white;
}
/* Remove the top border on an expanded row item */
.hide-top-border {
  border-top: none !important;
}
</style>
