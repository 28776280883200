import { defineStore } from 'pinia'
import apiCall from '../lib/api.js'

function encodedDate (date) {
  if (date instanceof Date) {
    return date.toJSON()
  }
  return date
}

export const useAuditStore = defineStore('audit', {
  state: () => {
    return {
      reportMetadata: [],
      reportName: '',
      startDate: '', // set default start date to null so that the date picker doesn't hide any data at first
      endDate: new Date(), // set default end date to today.
      reportData: [],
      reportDomain: '',
      reportApplication: '',
      reportAppDescription: ''
    }
  },
  getters: {
    currentReport: (state) => state.reportMetadata.find ? state.reportMetadata.find(meta => meta.name === state.reportName) : {},
    downloadUrl: (state) => {
      const url = new URL(`/api/report/download/${state.reportName}`, window.location.origin)
      if (state.startDate) {
        url.searchParams.append('date_start', encodedDate(state.startDate))
      }
      if (state.endDate) {
        url.searchParams.append('date_end', encodedDate(state.endDate))
      }
      if (state.reportDomain) {
        url.searchParams.append('domain_selected', state.reportDomain)
      }
      if (state.reportApplication) {
        url.searchParams.append('application_selected', state.reportApplication)
      }
      url.searchParams.append('time_zone', Intl.DateTimeFormat().resolvedOptions().timeZone)
      return url.href
    }
  },
  actions: {
    async getReportMetadata () {
      console.log('getting report metadata')
      const response = await apiCall('/api/report/metadata', { })
      if (response.ok) {
        const json = await response.json()
        this.reportMetadata = json.data
      } else {
        this.reportMetadata = []
      }
    },
    async getReport () {
      console.log('loading report')

      const url = new URL(`/api/report/${this.reportName}`, window.location.origin)

      if (this.isDomainAdmin) {
        // If a specific domain is selected, use it; otherwise, send all allowed domains
        const domains = this.reportDomain ? [this.reportDomain] : this.userDomains
        if (domains.length > 0) {
          url.searchParams.append('domains_selected', domains.join(','))
        }
      } else if (this.reportDomain) {
        // Global Admins or other roles can select a single domain
        url.searchParams.append('domain_selected', this.reportDomain)
      }

      if (this.reportApplication) {
        url.searchParams.append('application_selected', this.reportApplication)
      }
      if (this.startDate) {
        url.searchParams.append('date_start', encodedDate(this.startDate))
      }
      if (this.endDate) {
        url.searchParams.append('date_end', encodedDate(this.endDate))
      }
      url.searchParams.append('time_zone', Intl.DateTimeFormat().resolvedOptions().timeZone)
      const response = await apiCall(url.href, {
        loadingMessage: 'Loading report...',
        showToast: true
      })
      if (response.ok) {
        const json = await response.json()
        this.reportData = json.data
      } else {
        this.reportData = []
      }
    }
  }
})
