<template>
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center align-self-start">
      <h4>Mapped Permissions</h4>
      <button
        v-if="!readOnly"
        @click="openAddPermissionDialog"
        type="button"
        class="btn btn-sm btn-success ml-2"
      >
        <BIconPlusSquare /> Map
      </button>
    </div>
    <div class="d-flex flex-direction-column justify-content-begin align-items-center" style="justify-content: center; padding-bottom: 10px">
      <button v-show="currentIdentifier !== pageSize.valueOf()" @click="backFive" class="page-link"><BIconSkipBackwardFill/></button>
      <button v-show="currentIdentifier !== pageSize.valueOf()" @click="previousPage" class="page-link"><BIconPlayFill style="transform: scaleX(-1);"/></button>
      <span v-show="currentPageNumber > 1" @click="toBeginning" class="page-link" style="cursor: pointer;"> 1 </span>
      <span v-show="currentPagesAmount > 1" class="page-link border-primary" style="margin-right: 10px; margin-left: 10px; border-width: medium"> {{currentPageNumber.valueOf()}}</span>
      <span v-show="currentPageNumber < currentPagesAmount" @click="toEnd" class="page-link" style="cursor: pointer;">{{currentPagesAmount}}</span>
      <button v-show="currentIdentifier < permissionCount" @click="nextPage" class="page-link"><BIconPlayFill/></button>
      <button v-show="currentIdentifier < permissionCount" @click="forwardFive" class="page-link"><BIconSkipForwardFill/></button>
    </div>
  </div>
  <table class="table table-sm mt-2">
    <tbody>
      <tr v-if="!permDisplayList || permDisplayList.length === 0">
        <td colspan="3">
          <span class="text-muted">No permissions configured.</span>
        </td>
      </tr>
      <tr v-for="permission of permDisplayList" :key="permission.mappingId">
        <td>
          {{ permission.name }}
        </td>
      </tr>
    </tbody>
  </table>

  <AModal
    v-model="addPermissionMappingDialogOpen"
    @click-outside="closeDialogs"
    :style="{ 'max-width': '80%' }"
  >
  <template #header>
      <h3>Map Permissions</h3>
    </template>
    <template #body>
  <div class="d-flex gap-4 justify-content-center">
  <!-- Permissions available list -->
  <div class="list-container">
    <h5>Available Permissions</h5>
    <ul class="list-unstyled mt-2">
      <li
    v-for="permission in permissionsAvailableToMap"
    :key="permission.id"
    class="d-flex justify-content-between list-item"
    :class="{ selected: selectedPermission?.id === permission.id }"
    @click="selectPermission(permission, 'available')"
  >
    <span>{{ permission.name }}</span>
  </li>
    </ul>
  </div>

  <!-- Action Buttons -->
  <div class="buttons">
    <button
  @click="moveToPermissions"
  class="btn btn-sm btn-success mb-2"
>
  Add &gt;&gt;
</button>

<button
  @click="moveToAvailable"
  class="btn btn-sm btn-danger"
>
  Remove &lt;&lt;
</button>
  </div>

  <!-- Current permissions list -->
  <div class="list-container">
    <h5>Current Permissions</h5>
    <ul class="list-unstyled mt-2">
      <li v-if="!currentPermissions || currentPermissions.length === 0">
    <span class="text-muted">No permissions configured.</span>
  </li>
  <li
    v-for="permission in currentPermissions"
    :key="permission.mappingId"
    class="d-flex justify-content-between list-item"
    :class="{ selected: selectedPermission?.id === permission.id }"
    @click="selectPermission(permission, 'current')"
  >
  <!-- Above action selects the current permission -->
    <span>{{ permission.name }}</span>
  </li>
    </ul>
  </div>
</div>
</template>

<template #footer>
  <div class="d-flex justify-content-end w-100">
    <button @click="closeDialogs" class="btn btn-outline-secondary mr-2">Close <BIconXCircleFill /></button>
    <button @click="acceptPermissionMappingDialog" class="btn btn-success mr-2">Save Changes</button>
    <!-- Save changes and send the new permissions lists to be updated via the API -->
    </div>
</template>

  </AModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import AModal from '../components/AModal.vue'
import { usePermissionMappingStore } from '../stores/permissionMappingStore.js'
import { useApplicationsStore } from '../stores/applicationsStore.js'
import { BIconPlayFill, BIconSkipBackwardFill, BIconSkipForwardFill } from 'bootstrap-icons-vue'
import { storeToRefs } from 'pinia'
// import { useRoute } from 'vue-router'

const permissionMappingStore = usePermissionMappingStore()
const applicationsStore = useApplicationsStore()

const addPermissionMappingDialogOpen = ref(false)

const props = defineProps({ // eslint-disable-line no-undef
  readOnly: Boolean,
  appId: String, // warning: can be null
  clientId: String,
  appName: String
})

// const route = useRoute()
const { pageSize, currentIdentifier, currentPagesAmount, currentPageNumber, currentPermissions } = storeToRefs(applicationsStore)
const permissionCount = ref([])
const mappedPermissionsList = ref([])
const permissionsAvailableToMap = ref([])
const permDisplayList = ref([])
const selectedPermission = ref({})

watch(() => props, async () => {
  currentPageNumber.value = 1
  await permissionMappingStore.getPermissionsToMap()
  permissionCount.value = applicationsStore.currentPermissions.length
  currentIdentifier.value = pageSize.value
  sortPermissionsList(currentPermissions.value) // Sort the currentPermissions list
  permDisplayList.value = currentPermissions.value.slice(currentIdentifier.value - pageSize.value, currentIdentifier.value)
  mappedPermissionsList.value = applicationsStore.mappedPermissionsForAppId(props.appId) // refresh the list of currently mapped permissions
  permissionsAvailableToMap.value = permissionMappingStore.unmappedPermissions(mappedPermissionsList.value) // refresh the list of permissions that the user is able to add to this application
  sortPermissionsList(permissionsAvailableToMap.value) // Sort the permissionsAvailableToMap list after it is populated
}, { immediate: true })

function updateList () {
  sortPermissionsList(currentPermissions.value)
  currentIdentifier.value = (currentPageNumber.value) * pageSize.value
  permDisplayList.value = currentPermissions.value.slice(currentIdentifier.value - pageSize.value, currentIdentifier.value)
}

function openAddPermissionDialog () {
  addPermissionMappingDialogOpen.value = true
}

function closeDialogs () {
  addPermissionMappingDialogOpen.value = false
}

async function nextPage () {
  currentPageNumber.value++
  updateList()
}

async function previousPage () {
  currentPageNumber.value--
  updateList()
}

async function forwardFive () {
  if (currentPageNumber.value < currentPagesAmount.value - 5) {
    currentPageNumber.value += 5
  } else {
    currentPageNumber.value = currentPagesAmount.value
  }
  updateList()
}

async function backFive () {
  if (currentPageNumber.value > 5) {
    currentPageNumber.value -= 5
  } else {
    currentPageNumber.value = 1
  }
  updateList()
}

async function toBeginning () {
  currentPageNumber.value = 1
  updateList()
}

async function toEnd () {
  currentPageNumber.value = currentPagesAmount.value
  updateList()
}

// A method to get the difference between two arrays
function arrayDifference (arr1, arr2) {
  const idsInArr2 = new Set(arr2.value.map(obj => obj.id)) // Extract IDs into a Set
  return arr1.value.filter(obj => !idsInArr2.has(obj.id)) // Compare by `id`
}

// A method to sort the lists
function sortPermissionsList (list) {
  list.sort((a, b) => a.name.localeCompare(b.name))
}

// function for selecting a permission and highlighting it
function selectPermission (permission, listType) {
  if (selectedPermission.value?.id === permission.id) {
    if (listType === 'available') {
      moveToPermissions()
    } else if (listType === 'current') {
      moveToAvailable()
    }
    selectedPermission.value = {} // Deselect if clicking again
  } else {
    selectedPermission.value = { ...permission, listType }
  }
}

// Function for moving a permission from the available list to the mapped list
function moveToPermissions () {
  if (!selectedPermission.value || selectedPermission.value.listType !== 'available') return

  // Find the index of the permission in the available list
  const index = permissionsAvailableToMap.value.findIndex(p => p.id === selectedPermission.value.id)
  if (index !== -1) {
    // Move the permission from available to mapped list
    const [movedPermission] = permissionsAvailableToMap.value.splice(index, 1)
    currentPermissions.value.push(movedPermission)

    // Sort the mapped list alphabetically by name
    sortPermissionsList(currentPermissions.value)

    // Reset the selection to null instead of an empty object
    selectedPermission.value = null
  }
}

// Function for moving a permission from the mapped list to the available list
function moveToAvailable () {
  if (!selectedPermission.value || selectedPermission.value.listType !== 'current') return

  // Find the index of the permission in the mapped list
  const index = currentPermissions.value.findIndex(p => p.id === selectedPermission.value.id)
  if (index !== -1) {
    // Move the permission from mapped list to available list
    const [movedPermission] = currentPermissions.value.splice(index, 1)
    permissionsAvailableToMap.value.push(movedPermission)

    // Sort the available list alphabetically by name (or another property you wish)
    sortPermissionsList(permissionsAvailableToMap.value)
    // Reset the selection to null instead of an empty object
    selectedPermission.value = null
  }
}

// Function for saving the changes made to the permission mapping
async function acceptPermissionMappingDialog () {
  const removedPerms = arrayDifference(mappedPermissionsList, currentPermissions)
  const addedPerms = arrayDifference(currentPermissions, mappedPermissionsList)
  // Check if any permissions were added
  if (addedPerms.length > 0) {
    // Loop through the added permissions
    for (const permission of addedPerms) {
      const permissionId = permission.id
      if (permissionId) {
        await permissionMappingStore.addPermissionMapping({ // Add the permission
          appId: props.appId,
          clientId: props.clientId,
          permissionId: permission.id,
          appName: props.appName
        })
      } // else we don't want to try to save an empty permission
    }
  }
  // Check if any permissions were removed
  if (removedPerms.length !== 0) {
    // Loop through the removed permissions
    for (const permission of removedPerms) {
      if (!props.appId) { // shouldn't get here (since an app with a mapped permission has already been created in our system) but if we do at least we're logging it
        console.warn(`Expected app id for client id ${props.clientId} but none was found`)
      }
      await permissionMappingStore.removePermissionMapping({ // Remove the permission
        appId: props.appId,
        mappingId: permission.mappingId
      })
    }
  }
  await applicationsStore.getOneApplication({ appId: props.appId }) // reload the list of applications since a permission was added
  mappedPermissionsList.value = [...currentPermissions.value] // reset the list of currently mapped permissions
  updateList()
  closeDialogs()
}

</script>

<style>
.one-button {
  width: 8rem;
  min-width: fit-content;
}

.d-flex {
  display: flex;
}

.gap-4 {
  gap: 1rem; /* Adjust this to change spacing between lists and buttons */
}

.list-container {
  width: 38%; /* Adjust the width of the lists */
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 600px; /* Set a fixed height for the container */
  overflow-y: auto; /* Enables scrolling if content overflows */
}

.list-item {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

h5 {
  text-align: center;
  margin-bottom: 1rem;
}

/* TEST STYLES */

.selected {
  background-color: #007bff !important; /* Bootstrap primary blue */
  color: white !important;
  font-weight: bold;
  border-radius: 5px;
}
</style>
